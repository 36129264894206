import React from "react";
import cuid from "cuid";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Button,
} from "@material-ui/core";

import { FancyHr } from "../FancyHr";
import BoardMember from "./BoardMember";

import HeroAbout from "../../images/hero-plan-your-visit.jpg";
import LeadAbout from "../../images/lead-about.png";

import ImgHollyPittman from "../../images/boardmembers/holly-pittman.png";
import ImgCherylMills from "../../images/boardmembers/cheryl-mills.png";
import ImgNancyClarkBauder from "../../images/boardmembers/nancy-clark-bauder.png";
import ImgJoanaScholtz from "../../images/boardmembers/joana-scholtz.png";
import ImgRichardEJackson from "../../images/boardmembers/richard-e-jackson.png";
import ImgJoannUitenham from "../../images/boardmembers/joann-uitenham.png";
import ImgDinahZeck from "../../images/boardmembers/dinah-zeck.png";
import ImgSandiSmith from "../../images/boardmembers/sandi-smith.png";
import ImgEdnaWagner from "../../images/boardmembers/edna-wagner.png";
import ImgMichaelJoyner from "../../images/boardmembers/michael-joyner.png";

import PDF from "../../pdf/executive-director-job-description.pdf";

const boardMembers = [
  {
    id: cuid(),
    title: "Holly Pittman",
    bio: [
      "Mrs. Holly Pittman is a Leavenworth native with a strong interest in our community’ growth, overall prosperity and family oriented environment.  Holly has a degree in Public Health from the University of Kansas and was a Price Fellow at the Centers for Disease Control and Prevention in Atlanta, Georgia. She volunteers in many organizations that serve and fosters Leavenworth as a community of choice.  Holly is the President of the Leavenworth Education Foundation, Leavenworth Main Street and First Judicial District CASA Association. Holly is an avid promoter of our community and believes our history and regional contributions are an important story that needs to be told.  Holly and her husband Jeff live in Leavenworth and have three children.",
    ],
    bioBullets: null,
    img: ImgHollyPittman,
  },
  {
    id: cuid(),
    title: "Cheryl Mills",
    bio: [
      "Hi, my name is Cheryl Mills. I am a native Kansans and have lived in the Leavenworth/ Lansing community for more than 39 years. I have been a supporter of the Richard Allen Cultural Center and Museum since it’s conception, and an advocate of preserving our African American History. I am a wife and a mother of 4 children and we are proud grandparents of 4  wonderful grandkids.  As a retired, Registered Nurse, I believe in preserving and maintaining a healthy lifestyle, mind, body and soul. It is an honor to be   part of the Richard Allen Cultural center, who’s main goal is to educate and preserve our African American history and culture, generation after generation.",
    ],
    bioBullets: null,
    img: ImgCherylMills,
  },
  {
    id: cuid(),
    title: "Nancy Clark Bauder",
    bio: [
      "I am Nancy Clark Bauder.  I grew up in north Leavenworth, which has a very diverse and culturally rich history.  I met Mrs. Phyllis Bass as she began the process of working with the community to form what is now the Richard Allen Cultural Center.   I am so impressed by the mission and work of the RACC to educate all youth of our community through the museum and mentoring program.  I has been my joy to have volunteered for several years for center events and  on the board.",
      "I am a Leavenworth City Commissioner and former Mayor.",
    ],
    bioBullets: null,
    img: ImgNancyClarkBauder,
  },
  {
    id: cuid(),
    title: "Joana Scholtz",
    bio: [
      "Joana Scholtz is a retired USD 453 teacher.  She has lived in Leavenworth for 21 years and joined the RACCM Board in 2018.  Joana has been a long-time volunteer to the center and the after school tutoring program.  Joana is passionate about supporting the youth in her community and believes that all aspects of a community’s history is vital to creating an inclusive environment for our future.",
    ],
    bioBullets: null,
    img: ImgJoanaScholtz,
  },
  {
    id: cuid(),
    title: "Richard E. Jackson",
    bio: [
      "Richard E. “Rik” Jackson is retired military veteran and a Native of Leavenworth. He attended Lincoln Elementary School (E.M. Lawson) (North Leavenworth). Middle School, at Nettie Hartnett all city Junior High School, and graduated from Leavenworth Senior High School.  He graduated from Kansas State University with a Bachelor of Social Sciences.  In addition to being a musician and Band Leader/Vocalist Guitarist of the Penny Black Band of Leavenworth, he diligently serves his community by volunteering in organizations such as",
    ],
    bioBullets: [
      "Historic Leavenworth Downtown Preservation Commissioner: April 15, 2015 thru 15 April 2021",
      "Board of Directors: Richard Allen Cultural Center 1994-Present",
      "Member Bethel African Methodist Episcopal Church Leavenworth: Elected Trustee, Vice President Usher Board, and Choir Member/Gospel Aries (Men’s Gospel Group)",
      "Member Leavenworth Noon Lions Club, Board of Directors ",
      "Member Veterans Foreign Wars Post 12073 Elected Chaplain",
    ],
    img: ImgRichardEJackson,
  },
  {
    id: cuid(),
    title: "JoAnn Uitenham",
    bio: [
      "I, JoAnn Uitenham was born in Mobile, Al. I met the love of my life Fabias Uitenham in Nuremberg, Germany and married him December 16, 1983. We have two beautiful children Fabias II and Fatriziera. Three grand children Kamea, Kennedi and Fabias III. I am an Ordained Minister at First Missionary Baptist Church, President of Les Novelettes Club, Vise President of Richard Allen Culture Center&Museum, Member of Leavenworth Alumnae Chapter Delta Sigma Theta and Volunteer with CASA.",
    ],
    bioBullets: null,
    img: ImgJoannUitenham,
  },
  {
    id: cuid(),
    title: "Dinah Zeck",
    bio: [
      "My name is Dinah Zeck. I was born and raised in Dodge City, Kansas. My husband, Danny and I have lived in Leavenworth since 1972.  Danny was born and raised in Leavenworth.  We have 2 sons who were also raised in Leavenworth and attended Leavenworth Public Schools. I served on the Leavenworth Public Schools Education Foundation.  I am on the Leavenworth Alumni Board and am vice-president of the Saint Luke’s Cushing Hospital Foundation. My pride and joy is my family.  We have two married sons, four grandchildren and a great grandson. I am proud to serve on the board of Richard Allen Cultural Center. The programs offered at the center are very valuable to our community and benefit many.",
    ],
    bioBullets: null,
    img: ImgDinahZeck,
  },
  {
    id: cuid(),
    title: "Sandi Smith",
    bio: [
      "My name is Sandi Smith.  I have lived in Leavenworth for 10 1/2 years and have been the Secretary of the RACCM Board for 10 years. I had visited the Center prior to moving here and said that I wanted to volunteer at the Museum at some point.  I believe it's important to continue to educate the public of the rich African American history in our country.  I recently retired from USD 453 where I had been a paraprofessional at Leavenworth High School for 10 1/2 years so working with the youth to help them succeed is also high on my list of priorities.  The tutoring program at RACCM is stellar and is making a difference in our communities.",
    ],
    bioBullets: null,
    img: ImgSandiSmith,
  },
  {
    id: cuid(),
    title: "Edna Wagner",
    bio: null,
    bioBullets: null,
    img: ImgEdnaWagner,
  },
  {
    id: cuid(),
    title: "Michael S. Joyner, MD",
    bio: null,
    bioBullets: null,
    img: ImgMichaelJoyner,
  },
];

const About = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpenPDF = () => {
    window.open(PDF, "_blank");
  };
  return (
    <>
      <Box py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
        <Grid container>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent={isSmall ? "center" : "left"}
                >
                  <Typography variant="h4" align="center">
                    About
                  </Typography>
                </Box>
                <FancyHr center={isSmall} />

                <Typography paragraph align={isSmall ? "center" : "left"}>
                  The Richard Allen Cultural Center/Museum opened its doors on
                  July 19, 1992. pocket option We are located across the street
                  from the historic Bethel AME Church which was part of the
                  Underground Railroad. The center offers a glimpse into the
                  history of African-Americans, both local and nationwide. It
                  was created to ensure that the rich heritage of African-
                  Americans and their profound effect on American society would
                  not be lost.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="center">
                  <Box component="img" src={LeadAbout} alt="statue"></Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} />
        </Grid>
      </Box>
      <Box
        py={isSmall ? 2 : 13}
        px={isSmall ? 2 : "initial"}
        style={{ backgroundColor: `#EFEFEF` }}
      >
        <Grid container>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h4" align="center">
                Board Members
              </Typography>
            </Box>

            <FancyHr center />
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="space-evenly"
            >
              {boardMembers.map(({ id, title, img, bio, bioBullets }) => (
                <BoardMember
                  title={title}
                  img={img}
                  bio={bio}
                  bioBullets={bioBullets}
                  key={id}
                />
              ))}
            </Box>
            <Box>
              <Box mt={4} mb={1} textAlign={`center`}>
                <Typography>
                  Interested in being a board member? View our openings below.
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenPDF}
                >
                  Executive Director
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} />
        </Grid>
      </Box>
    </>
  );
};

export default About;
