import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import _ from "lodash";

const BoardMember = ({ id, title, img, bio, bioBullets }) => {
  console.log(bio);
  console.log(bioBullets);
  return (
    <Box mb={3}>
      <Box component="img" src={img} alt={title} />
      <Typography variant="h6" align="center">
        {title}
      </Typography>
    </Box>
  );
};

BoardMember.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  img: PropTypes.string,
  bio: PropTypes.arrayOf([PropTypes.string]),
  bioBullets: PropTypes.arrayOf([PropTypes.string]),
};

export default BoardMember;
